import React, { useEffect, useState } from "react";
import { useHealthContext } from "../context/appContext";
import RadioInput from "../containers/input.radio";
import ContainerWithTitle from "../containers/title.container";
import ButtonContainer from "../containers/button";
import ErrorMessage from "../containers/errorMessage";

const QuestionSeven = ({ onNext, onPrev }) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    errorMessage,
    setErrorMessage,
  } = useHealthContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.phyiscal_activity || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
    } else {
      // setErrorMessage("Please select an option.");
      setErrorMessage("Please select an option.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null);
    setValue(event.target.value);
    onNext(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="What is the intensity of your physical activity?"
      handleBack={onPrev}
      isContinueButton={false}
      // Use "&&" for the Switch the next line
      message="Following a diet plan can be hard. The HealthSpan Co.’s C-M-D formula makes it easy.  
&&
Users that also adopt a healthy lifestyle typically lose 0.5-1 kgs per week "
    >
      <div className="radio-container flex flex-col">
        <RadioInput
          boldLabel="Light"
          discription="Light-intensity exercises like casual walking, bicycling, stretching or housework."
          value="Light"
          colorChecked={value === "Light"}
          onChange={handleRadioChange}
        />
        <RadioInput
          boldLabel="Moderate"
          discription="Moderate-intensity exercises like brisk walking, low-impact aerobics, dancing, yoga, weight training, housework that requires scrubbing."
          value="Moderate"
          colorChecked={value === "Moderate"}
          onChange={handleRadioChange}
        />
        <RadioInput
          boldLabel="High"
          discription="High-intensity exercises like jogging, running, mountain climbing, fast bicycling, competitive sports like tennis/badminton/football/swimming."
          value="High"
          colorChecked={value === "High"}
          onChange={handleRadioChange}
        />
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default QuestionSeven;
