// ContainerWithTitle.js
import React from "react";
import ErrorMessage from "./errorMessage";
import { useHealthContext } from "../context/appContext";

const ContainerWithTitle = ({
  title,
  description,
  children,
  options,
  formData,
  handleOptionSelect,
  category,
  message,
  noteTitle,
  handleNext,
  handleBack,
  isContinueButton,
}) => {
  const { errorMessage } = useHealthContext();
  return (
    <form className="capitalize">
      <div className="bg-white rounded-xl p-8 shadow-md h- ">
        <div className="animate-fadeIn text-start">
          <Heading title={title} description={description} />

          {/* <CheckboxContentContainer
          options={options}
          formData={formData}
          handleOptionSelect={handleOptionSelect}
          category={category}
        /> */}

          {children}

          {noteTitle || message ? (
            <NoteContainer message={message} title={noteTitle} />
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="flex justify-between my-8 text-xs md:text-base">
        <div
          onClick={handleBack}
          className="px-6 cursor-pointer py-3 bg-gray-100 hover:bg-[#2F412F] hover:text-white rounded-lg font-semibold"
        >
          Previous
        </div>
        {isContinueButton === false ? (
          ""
        ) : (
          <button
            onClick={handleNext}
            type="submit"
            className="px-6 py-3 bg-[#2F412F] text-white rounded-lg font-semibold ml-auto hover:bg-[#3D533D] transition-colors"
          >
            Continue
          </button>
        )}
      </div>
    </form>
  );
};

export default ContainerWithTitle;

const Heading = ({ title, description }) => {
  return (
    <div className="text-start">
      <h2 className="text-lg md:text-2xl font-bold md:mb-2">{title}</h2>
      <p className="text-gray-600 mb-6 text-xs md:text-base">{description}</p>
    </div>
  );
};

// const CheckboxContentContainer = ({
//   options,
//   formData,
//   handleOptionSelect,
//   category,
// }) => {
//   return (
//     <div className="grid gap-4">
//       {options.map((option) => (
//         <CheckContainer
//           key={option.id}
//           goal={option}
//           formData={formData}
//           handleOptionSelect={handleOptionSelect}
//           category={category}
//         />
//       ))}
//     </div>
//   );
// };

const NoteContainer = ({ message, title }) => {
  const messages = message.split("&&").map((msg, index) => (
    <p key={index} className="text-xs md:text-lg text-start">
      {msg}
    </p>
  ));
  return (
    <div className="bg-orange-50 border-l-4 border-[#FFB74D] p-4 rounded mt-6">
      {title && <strong className="">{title}:</strong>}
      {messages}
    </div>
  );
};
