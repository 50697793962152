import React, { useEffect, useState } from "react";
import { useHealthContext } from "../context/appContext";
import ContainerWithTitle from "../containers/title.container";
import CheckboxInput from "../containers/input.checkbox";
import ButtonContainer from "../containers/button";
import ErrorMessage from "../containers/errorMessage";

const Health_goals = ({ onNext, onPrev }) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    errorMessage,
    setErrorMessage,
  } = useHealthContext();
  const [value, setValue] = useState([]);
  // const [, setErrorMessage] = useState(null);

  useEffect(() => {
    setValue(payload?.health_goals || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value.length > 0) {
      const isConstipationSelected =
        value.includes("Constipation") || value.includes("Bloating & Gas");
      let nextPage = currentPage + 2;
      if (isConstipationSelected) {
        nextPage = currentPage + 1;
      }
      onNext(value);
      setCurrentPage(nextPage);
    } else {
      setErrorMessage("Please select at least one health goal.");
    }
  };

  const handleCheckboxChange = (event) => {
    const { value: checkboxValue, checked } = event.target;
    if (checked) {
      setErrorMessage(null);
      setValue([...value, checkboxValue]);
    } else {
      setValue(value.filter((item) => item !== checkboxValue));
    }
  };

  const isCheckboxChecked = (checkboxValue) => {
    return value.includes(checkboxValue);
  };

  return (
    <ContainerWithTitle
      title="In addition to weight loss, what are your other health goals?"
      handleNext={handleSubmit}
      handleBack={onPrev}
      isContinueButton={value.length>0 ? true : false}
      // {value? true}
      // Apply "&&" for Change the line,
      message="Great! We're excited to partner with you to hit your goals. &&
Weight loss is an important goal, but The HealthSpan Co.’s mission is helping people achieve their fitness goals, whatever that might be. "
    >
      <ul className="flex flex-col text-left">
        <CheckboxInput
          label="Inch Loss"
          value="Inch Loss"
          checked={isCheckboxChecked("Inch Loss")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Reduce Belly Fat"
          value="Reduce Belly Fat"
          checked={isCheckboxChecked("Reduce Belly Fat")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Increased Energy Levels"
          value="Increased Energy Levels"
          checked={isCheckboxChecked("Increased Energy Levels")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Bloating & Gas"
          value="Bloating & Gas"
          checked={isCheckboxChecked("Bloating & Gas")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Constipation"
          value="Constipation"
          checked={isCheckboxChecked("Constipation")}
          onChange={handleCheckboxChange}
        />
      </ul>

      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default Health_goals;
