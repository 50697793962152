import React, { createContext, useContext, useEffect, useState,  } from "react";

const AppContext = createContext();

export const useHealthContext = () => useContext(AppContext);

const data = {
  name: "",
  phone: "",
  email: "",
  age: "",
  gender: "",
  height: "",
  health_goals: [],
  gat_Symptom: "",
  exercise: "",
  phyiscal_activity: "",
  medical_condition: "",
  diagnosed_conditions: [],
  eating_habits: "",
  allergic: [],
  medication: "",
  sleep: "",
};

const HealthSpendProvider = ({ children }) => {
  const [payload, setPayload] = useState(data);
  const [headers, setHeaders] = useState("vitals")
  const [currentPage, setCurrentPage] = useState(0);
  const [currentTab, setCurrentTab] = useState("vitals");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(null)
  const [skipquestion, setSkipquestion] = useState({
    question: "How many hours of sleep do you get in a night on average?",
    response: null,
  });

  useEffect(() => {
    setErrorMessage(null)
    if (currentPage >= 0 && currentPage <= 6) {
      setCurrentTab("vitals");
    } else if (currentPage > 6 && currentPage < 9) {
      setCurrentTab("goals");
    } else if (currentPage >= 9 && currentPage <= 11) {
      setCurrentTab("movement&sleep");
    } else if (currentPage >= 12) {
      setCurrentTab("diet&health");
    }
    //
  }, [currentPage]);

  const sessionValue = sessionStorage.getItem('quiz_Id');


  // console.log("currentPage", currentPage);
  // console.log("payload", payload);
  // console.log("currentTab", currentTab);
  // console.warn("setErrorMessage", errorMessage);
  return (
    <AppContext.Provider
      value={{
        payload,
        setPayload,
        currentPage,
        setCurrentPage,
        currentTab,
        setCurrentTab,
        skipquestion,
        setSkipquestion,
        errorMessage,
        setErrorMessage,loading, setLoading,sessionValue
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default HealthSpendProvider;



