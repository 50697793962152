import React, { useEffect, useState } from "react";
import { useHealthContext } from "../context/appContext";
import RadioInput from "../containers/input.radio";
import ContainerWithTitle from "../containers/title.container";
import ButtonContainer from "../containers/button";
import ErrorMessage from "../containers/errorMessage";

const QuestionFour = ({ onNext, onPrev }) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    errorMessage,
    setErrorMessage,
  } = useHealthContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.gat_Symptom || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
    } else {
      setErrorMessage(
        "Please select how often you are bothered by gut symptoms."
      );
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null);
    setValue(event.target.value);
    onNext(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="How often are you bothered by gut symptoms like bloating, reflux, constipation?"
      handleBack={onPrev}
      isContinueButton={false}
    >
      <div className="radio-container whitespace-pre flex  flex-col gap-0 lg:gap-5">
        <RadioInput
          label="Less than once a month"
          value="Less than once a month"
          colorChecked={value === "Less than once a month"}
          onChange={handleRadioChange}
        />

        <RadioInput
          label="1-3 times a month"
          value="1-3 times a month"
          colorChecked={value === "1-3 times a month"}
          onChange={handleRadioChange}
        />

        <RadioInput
          label="1-2 times a week"
          value="1-2 times a week"
          colorChecked={value === "1-2 times a week"}
          onChange={handleRadioChange}
        />

        <RadioInput
          label="3 or more times per week"
          value="3 or more times per week"
          colorChecked={value === "3 or more times per week"}
          onChange={handleRadioChange}
        />
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default QuestionFour;
