import React, { useState, useEffect } from "react";
import { useHealthContext } from "../context/appContext";
import RadioInput from "../containers/input.radio";
import ContainerWithTitle from "../containers/title.container";
import ButtonContainer from "../containers/button";

const Question13 = ({ onNext, onPrev }) => {
  const { payload, setPayload, currentPage, setCurrentPage, setErrorMessage } =
    useHealthContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.medication || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
    } else {
      setErrorMessage("Please select an option.");
    }
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    onNext(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="Do you take regular medication or over-the-counter drugs?"
      handleBack={onPrev}
      isContinueButton={false}
    >
      <div className="radio-container">
        <RadioInput
          label="Yes"
          value="Yes"
          colorChecked={value === "Yes"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="No"
          value="No"
          colorChecked={value === "No"}
          onChange={handleRadioChange}
        />
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default Question13;
