import React, { useEffect, useRef, useState, useCallback } from "react";
import Home from "./components/Home";
import QuestionTwo from "./components/Email";
import Health_goals from "./components/Health_goals";
import QuestionFour from "./components/QuestionFour";
import QuestionFive from "./components/QuestionFive";
import QuestionSix from "./components/QuestionSix";
import LastPage from "./components/LastPage";
import "./App.css";
import QuestionSeven from "./components/QuestionSeven";
import QuestionEight from "./components/QuestionEight";
import QuestionNine from "./components/QuestionNine";
import PhoneNumber from "./components/PhoneNumber";
import Name from "./components/Name";
import Email from "./components/Email";
import { FaArrowLeft } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import Age from "./components/age";
import Gender from "./components/gender";
import Weight from "./components/weight";
import Height from "./components/height";
import { useHealthContext } from "./context/appContext";
import Questioneleven from "./components/questioneleven";
import QuestionTen from "./components/questionTen";
import Questiontwilve from "./components/questiontwilve";
import Question13 from "./components/Question13";
import axios from "axios";
import Loader from "./containers/loader.js";
import { apiUrl } from "./config/config.js";
import HealthAssessment from "./appUi";

function App() {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [setShowNote] = useState(false);

  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    currentTab,
    setCurrentTab,
    skipquestion,
    loading,
    setLoading,
    sessionValue,
  } = useHealthContext();

  const validatePersonalInfo = () => {
    const newErrors = {};
    const nameRegex = /^[a-zA-Z\s]{2,50}$/;
    // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/;

    if (!nameRegex.test(formData?.name)) {
      newErrors.name = "Please enter a valid full name";
    }
    if (!emailRegex.test(formData?.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!phoneRegex.test(formData?.phone)) {
      newErrors.phone = "Please enter a valid phone number";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleOptionSelect = (category, value) => {
    if (category === "goals") {
      setFormData((prev) => ({
        ...prev,
        goals: prev.goals.includes(value)
          ? prev.goals.filter((g) => g !== value)
          : [...prev.goals, value],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [category]: value,
      }));
    }
  };
  // const progress = ((currentPage - 1) / (totalSteps - 1)) * 100;

  const totalSteps = 15; // Update this based on the total number of steps

  const progressPercentage = ((currentPage / totalSteps) * 100).toFixed(0);

  const [healthgoals, setHealthgoals] = useState(null);
  const [medicalcondition, setMedicationCondition] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [IsQuizStarted, setIsQuizStarted] = useState(true);

  const payloadRef = useRef();

  useEffect(() => {
    payloadRef.current = payload;
  }, [payload]);

  useEffect(() => {
    const hasValueIsTrue = () => {
      if (payload.health_goals) {
        return (
          payload.health_goals.includes("Bloating & Gas") ||
          payload.health_goals.includes("Constipation")
        );
      }
      return false;
    };

    const hasMedical_condition = () => payload.medical_condition === "Yes";
    const hasExercise = () => payload.exercise === "No";

    // console.warn("hasExercise", hasExercise());

    setHealthgoals(hasValueIsTrue());

    setPayload((prevData) => {
      let updatedData = { ...prevData };
      let needsUpdate = false;

      if (hasValueIsTrue() === false && prevData.gat_Symptom !== null) {
        updatedData.gat_Symptom = null;
        needsUpdate = true;
      } else if (
        hasMedical_condition() === false &&
        prevData.diagnosed_conditions.length > 0
      ) {
        updatedData.diagnosed_conditions = [];
        needsUpdate = true;
      } else if (
        hasExercise() === true &&
        prevData.phyiscal_activity !== "light"
      ) {
        updatedData.phyiscal_activity = "light";
        needsUpdate = true;
      }

      return needsUpdate ? updatedData : prevData;
    });
  }, [payload.health_goals, payload.medical_condition, payload.exercise]);

  useEffect(() => {
    if (payload.exercise === "No") {
      setPayload((prevData) => ({ ...prevData, phyiscal_activity: "light" }));
    }
  }, [payload.exercise]);

  const PrevHandler = () => {
    setCurrentPage((prevPage) => {
      if (healthgoals === false && prevPage === 7) {
        return prevPage - 2;
      } else if (
        payloadRef.current.medical_condition === "No" &&
        prevPage === 12
      ) {
        return prevPage - 2;
      } else if (payloadRef.current.exercise === "No" && prevPage === 9) {
        return prevPage - 2;
      } else {
        return prevPage - 1;
      }
    });
  };

  const HandleNext = (pageValue, pageName) => {
    setPayload((prevData) => ({ ...prevData, [pageName]: pageValue }));
    setCurrentPage((prevPage) => {
      if (pageName === "medical_condition" && pageValue === "No") {
        return prevPage + 2;
      } else if (pageName === "exercise" && pageValue === "No") {
        return prevPage + 2;
      } else {
        return prevPage + 1;
      }
    });

    const newPayload = {
      ...payload,
      [pageName]: pageValue,
    };

    const data = {
      sessionId: sessionValue,
      formData: newPayload,
    };

    const config = {
      method: "post",
      url: `${apiUrl}/api/v1/session/update`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Session updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Failed to update session:", error);
      });
  };

  const FirstNextHandle = (e) => {
    e.preventDefault();
    if (!validatePersonalInfo()) {
      return;
    }
    setPayload((prevData) => ({
      ...prevData,
      name: formData?.name,
      email: formData?.email,
      phone: formData?.phone,
    }));
    setCurrentPage((prevPage) => prevPage + 1);
    setFormData({});

    const sessionCreate = async () => {
      try {
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${apiUrl}/api/v1/session/create`,
          headers: {},
        };

        const response = await axios.request(config);
        if (response.data.success === true) {
          const sessionId = response.data.sessionId;
          sessionStorage.setItem("quiz_Id", sessionId);
          updateSession(sessionId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const updateSession = async (sessionId) => {
      try {
        const newPayload = {
          ...payload,
          name: formData?.name,
          email: formData?.email,
          phone: formData?.phone,
        };
        const data = {
          sessionId: sessionId,
          formData: newPayload,
        };

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${apiUrl}/api/v1/session/update`,
          headers: {},
          data: data,
        };

        await axios.request(config);
        // Handle successful response
      } catch (error) {
        console.log(error);
      }
    };

    sessionCreate();
  };

  // console.warn("==>", currentPage);
  console.log("==>", payload);

  const FinishHandler = () => {
    const sesssionPayload = {
      sessionId: sessionValue,
    };
    try {
      setLoading(true);
      setIsLoading(true);
      let sessionConfig = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/v1/session/delete`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(sesssionPayload),
      };

      axios.request(sessionConfig).then((response) => {
        console.log(JSON.stringify("Session Delete", response.data));
        sessionStorage.removeItem("quiz_Id");
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/v1/diagnose/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };

      // console.log(JSON.stringify(payload));
      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify("response.data", response.data.data._id));
          setIsLoading(true);
          if (response.data.success === true && response.data?.data._id) {
            window.location.href = `https://thehealthspanco.com/pages/report?healthspanid=${response.data?.data?._id}`;
            setCurrentPage(0);
            setPayload([]);
          } else {
            setLoading(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setIsLoading(false);

          console.log(error);
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  console.log("payload >>>", payload);

  const Header = () => (
    <div className="flex  w-full  justify-center items-center relative py-3  bg-[#2d3d23] text-white">
      <div className="w-11/12 relative gap-2  flex items-center justify-center ">
        {/* logo */}
        <img
          src={`https://thehealthspanco.com/cdn/shop/files/Logo_280x100px.png?v=1706525837&width=240`}
          className={` w-24 lg:w-32   brightness-[15000%] cursor-pointer`}
          onClick={() => {
            window.location.href = "https://thehealthspanco.com/";
          }}
        />
        {/* <div className=' text-sm lg:text-base'>Weight & Waist Assessment.</div> */}
      </div>
    </div>
  );

  console.log("currentPage", currentPage);
  return !IsQuizStarted ? (
    <div className="App gap-10">
      {isLoading && <Loader />}

      <div className="min-h-screen w-full bg-gray-100 overflow-y-auto">
        {/* Header */}
        <header className="bg-[#2F412F] sticky top-0 z-50 p-5 flex justify-between items-center">
          <div className="text-white text-lg md:text-2xl font-bold">
            The HealthSpan Co.
          </div>
          <button
            onClick={() => {
              setCurrentPage(0);
              setPayload("");
              window.location.href = "https://thehealthspanco.com/";
            }}
            className="bg-white px-4 text-xs md:text-base p-1 md:py-2 rounded text-red-600 no-underline"
          >
            Exit
          </button>
        </header>

        <div className="max-w-4xl mx-auto p-5 h-full">
          {/* Progress Bar */}
          <div className="my-5">
            <div className="h-1.5 bg-gray-200 rounded overflow-hidden">
              <div
                className="h-full bg-[#FFB74D] transition-all duration-500"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
            <div className="flex gap-1.5 md:gap-2.5 mt-2.5 overflow-auto">
              {[
                "Personal Info",
                "Goals",
                "Movement",
                window.innerWidth > 768 ? "Diet & Health" : "Diet",
                "Results",
              ]
                .filter((step, index) => window.innerWidth > 768 || index !== 4)
                .map((step, index) => (
                  <div
                    key={step}
                    className={`flex-1 p-2 md:p-4 text-xs text-nowrap md:text-base text-center rounded-lg cursor-pointer transition-all
                  ${
                    (currentPage <= 4 && step === "Personal Info") ||
                    (currentPage >= 5 &&
                      currentPage <= 6 &&
                      step === "Goals") ||
                    (currentPage >= 7 &&
                      currentPage <= 9 &&
                      step === "Movement") ||
                    (currentPage >= 10 &&
                      currentPage <= 14 &&
                      (step === "Diet & Health" || step === "Diet")) ||
                    (currentPage === 15 && step === "Results")
                      ? "bg-[#2F412F] text-white"
                      : "bg-white"
                  }`}
                  >
                    {step}
                  </div>
                ))}
            </div>
          </div>
          {currentPage === 0 && (
            <form>
              <div className="bg-white rounded-xl p-8 shadow-md">
                <div className="animate-fadeIn text-start">
                  <Heading
                    title="Let's Get Started"
                    description="Tell us a bit about yourself"
                  />

                  <div className="space-y-6">
                    <InputField
                      label="Full Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      error={errors.name}
                    />

                    <InputField
                      label="Email Address"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={errors.email}
                    />

                    <InputField
                      label="Mobile Number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      error={errors.phone}
                    />
                  </div>

                  <NoteContainer
                    message="Your information is protected and will never be shared."
                    title="Privacy Guaranteed"
                  />
                </div>
              </div>
              <div className="flex justify-start mt-8 w-full text-xs md:text-base">
                <button
                  onClick={FirstNextHandle}
                  type="submit"
                  className="px-6 py-3 bg-[#2F412F] text-white rounded-lg font-semibold ml-auto hover:bg-[#3D533D] transition-colors"
                >
                  Continue
                </button>
              </div>
            </form>
          )}
          {currentPage === 1 && (
            <Age
              onNext={(data) => HandleNext(data, "age")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 2 && (
            <Gender
              onNext={(data) => HandleNext(data, "gender")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 3 && (
            <Weight
              onNext={(data) => HandleNext(data, "weight")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 4 && (
            <Height
              onNext={(data) => HandleNext(data, "height")}
              onPrev={PrevHandler}
              // onClick={SessionUpdateHandler}
            />
          )}
          {currentPage === 5 && (
            <Health_goals
              onNext={(data) => HandleNext(data, "health_goals")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 6 && (
            <QuestionFour
              onNext={(data) => HandleNext(data, "gat_Symptom")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 7 && (
            <QuestionFive
              onNext={(data) => HandleNext(data, "exercise")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 8 && (
            <QuestionSeven
              onNext={(data) => HandleNext(data, "phyiscal_activity")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 9 && (
            <QuestionEight
              onNext={(data) => HandleNext(data, "sleep")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 10 && (
            <QuestionNine
              onNext={(data) => HandleNext(data, "medical_condition")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 11 && (
            <QuestionTen
              onNext={(data) => HandleNext(data, "diagnosed_conditions")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 12 && (
            <Questioneleven
              onNext={(data) => HandleNext(data, "eating_habits")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 13 && (
            <Questiontwilve
              onNext={(data) => HandleNext(data, "allergic")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 14 && (
            <Question13
              onNext={(data) => HandleNext(data, "medication")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 15 && (
            <LastPage onFinish={(data) => FinishHandler(data)} />
          )}{" "}
          {/* {currentPage === 6 && (
            <div className="animate-fadeIn">
              <Heading
                title="Your Health Goals"
                description="Select all that apply to personalize your journey"
              />

              <div className="grid gap-4">
                {[
                  {
                    id: "weight",
                    title: "Weight Management",
                    desc: "Achieve and maintain a healthy weight",
                  },
                  {
                    id: "energy",
                    title: "Energy Boost",
                    desc: "Improve daily energy levels",
                  },
                  {
                    id: "strength",
                    title: "Build Strength",
                    desc: "Increase muscle tone and strength",
                  },
                  {
                    id: "sleep",
                    title: "Better Sleep",
                    desc: "Improve sleep quality and patterns",
                  },
                ].map((goal) => (
                  <CheckContainer
                    key={goal.id}
                    goal={goal}
                    formData={formData}
                    handleOptionSelect={handleOptionSelect}
                    category="goals"
                  />
                ))}
              </div>
              {
                <NoteContainer
                  message="Setting specific, measurable health goals increases your chances of success by up to 95%"
                  title="Did you know?"
                />
              }
            </div>
          )} */}
          {/* {currentPage === 100 && (
            <div className="animate-fadeIn">
              <Heading
                title="Physical Activity Level"
                description="Select your typical activity level"
              />

              <div className="grid gap-4">
                {[
                  {
                    id: "light",
                    title: "Light Activity",
                    desc: "Walking, light stretching, casual activities",
                  },
                  {
                    id: "moderate",
                    title: "Moderate Activity",
                    desc: "Brisk walking, yoga, dancing, active lifestyle",
                  },
                  {
                    id: "high",
                    title: "High Activity",
                    desc: "Running, intense workouts, competitive sports",
                  },
                ].map((activity) => (
                  <CheckContainer
                    key={activity.id}
                    goal={activity}
                    formData={formData}
                    handleOptionSelect={handleOptionSelect}
                    category="activity"
                  />
                ))}
              </div>
            </div>
          )} */}
          {/* {currentPage === 100 && (
            <div className="animate-fadeIn">
              <Heading
                title="Diet & Health"
                description="Tell us about your eating habits"
              />

              <div className="grid gap-4">
                {[
                  {
                    id: "balanced",
                    title: "Balanced Diet",
                    desc: "Mix of all food groups",
                  },
                  {
                    id: "vegetarian",
                    title: "Vegetarian",
                    desc: "Plant-based with dairy and eggs",
                  },
                  {
                    id: "vegan",
                    title: "Vegan",
                    desc: "Completely plant-based",
                  },
                ].map((diet) => (
                  <CheckContainer
                    key={diet.id}
                    goal={diet}
                    formData={formData}
                    handleOptionSelect={handleOptionSelect}
                    category="diet"
                  />
                ))}
              </div>
            </div>
          )} */}
          {/* {currentPage === 50 && (
            <div className="text-center p-5 md:p-10 animate-fadeIn">
              <div className="w-20 h-20 bg-[#2F412F] rounded-full flex items-center justify-center text-white text-4xl mx-auto mb-5">
                ✓
              </div>
              <h2 className="text-lg md:text-2xl font-bold mb-4">
                Your Assessment is Complete!
              </h2>
              <p className="mb-6 text-sm md:text-base">
                We've analyzed your responses and created a personalized health
                plan.
              </p>
              <a
                // href="thc-result.html"
                className="inline-block text-xs  md:text-base bg-[#2F412F] text-white px-6 py-3 rounded-lg font-semibold hover:bg-[#3D533D] transition-colors"
              >
                View Your Results
              </a>
            </div>
          )} */}
          {/* Navigation Buttons */}
          {/* {currentPage < totalSteps && (
            <div className="flex justify-between mt-8 text-xs md:text-base">
              {currentPage > 1 && (
                <button
                  onClick={handleBack}
                  className="px-6 py-3 bg-gray-100 rounded-lg font-semibold"
                >
                  Previous
                </button>
              )}
              <button
                onClick={handleNext}
                className="px-6 py-3 bg-[#2F412F] text-white rounded-lg font-semibold ml-auto hover:bg-[#3D533D] transition-colors"
              >
                Continue
              </button>
            </div>
          )} */}
        </div>
      </div>

      {/* <HealthAssessment /> */}

      {/* {currentPage === 0 && (
        <Name onNext={(data) => HandleNext(data, "name")} />
      )}

      

      {currentPage === 1 && (
        <PhoneNumber onNext={(data) => HandleNext(data, "phone")} />
      )}
      {currentPage === 2 && (
        <Email
          onNext={(data) => HandleNext(data, "email")}
          // onClick={SessionUpdateHandler}
        />
      )}
      {currentPage === 3 && <Age onNext={(data) => HandleNext(data, "age")} />}
      {currentPage === 4 && (
        <Gender onNext={(data) => HandleNext(data, "gender")} />
      )}
      {currentPage === 5 && (
        <Weight onNext={(data) => HandleNext(data, "weight")} />
      )}
      {currentPage === 6 && (
        <Height
          onNext={(data) => HandleNext(data, "height")}
          // onClick={SessionUpdateHandler}
        />
      )}
      {currentPage === 7 && (
        <Health_goals onNext={(data) => HandleNext(data, "health_goals")} />
      )}
      {currentPage === 8 && (
        <QuestionFour onNext={(data) => HandleNext(data, "gat_Symptom")} />
      )}
      {currentPage === 9 && (
        <QuestionFive onNext={(data) => HandleNext(data, "exercise")} />
      )}
      {currentPage === 10 && (
        <QuestionSeven
          onNext={(data) => HandleNext(data, "phyiscal_activity")}
        />
      )}
      {currentPage === 11 && (
        <QuestionEight onNext={(data) => HandleNext(data, "sleep")} />
      )}
      {currentPage === 12 && (
        <QuestionNine
          onNext={(data) => HandleNext(data, "medical_condition")}
        />
      )}
      {currentPage === 13 && (
        <QuestionTen
          onNext={(data) => HandleNext(data, "diagnosed_conditions")}
        />
      )}
      {currentPage === 14 && (
        <Questioneleven onNext={(data) => HandleNext(data, "eating_habits")} />
      )}
      {currentPage === 15 && (
        <Questiontwilve onNext={(data) => HandleNext(data, "allergic")} />
      )}
      {currentPage === 16 && (
        <Question13 onNext={(data) => HandleNext(data, "medication")} />
      )}
      {currentPage === 17 && (
        <LastPage onFinish={(data) => FinishHandler(data)} />
      )} */}
    </div>
  ) : (
    <LandingPage setIsQuizStarted={setIsQuizStarted} Header={Header} />
  );
}

export default App;

export const InputField = ({
  label,
  name,
  value,
  onChange,
  error,
  ...props
}) => {
  return (
    <div>
      <label className="block mb-1 text-xs md:text-base font-medium text-zinc-700">
        {label}
      </label>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        className="w-full p-2.5 text-sm md:text-lg px-5 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-[#2F412F]"
        placeholder={`Enter your ${label}`}
        {...props}
      />
      {error && <span className="text-red-600 text-sm mt-1">{error}</span>}
    </div>
  );
};

export const Heading = ({ title, description }) => {
  return (
    <div className="text-start">
      <h2 className="text-lg md:text-2xl font-bold md:mb-2">{title}</h2>
      <p className="text-gray-600 mb-6 text-xs md:text-base">{description}</p>
    </div>
  );
};

const CheckContainer = ({ goal, formData, handleOptionSelect, category }) => {
  return (
    <div
      key={goal.id}
      onClick={() => handleOptionSelect(category, goal.id)}
      className={`p-5 rounded-lg cursor-pointer transition-all text-start
                      ${
                        formData[category]?.includes(goal.id)
                          ? "bg-[#3D533D] text-white border-2 border-transparent"
                          : "bg-gray-100 hover:border-[#2F412F] border-2 border-transparent"
                      }`}
    >
      <div className="font-semibold mb-1 md:mb-3 text-sm md:text-lg">
        {goal.title}
      </div>
      <div className="text-xs md:text-base opacity-90 ">{goal.desc}</div>
    </div>
  );
};

const NoteContainer = ({ message, title }) => {
  return (
    <div className="bg-orange-50 border-l-4 border-[#FFB74D] p-4 rounded mt-6 text-xs md:text-lg text-start">
      <strong className="">{title}:</strong> {message}
    </div>
  );
};

const LandingPage = ({ setIsQuizStarted, Header }) => {
  return (
    <div className="h-screen flex flex-col items-center    text-center w-full backdrop-blur-lg ">
      <Header />
      <div className="container md:w-[70%] p-4 bg-white">
        <header className="bg-gradient-to-r p-6 bg-[#2d3d23] text-white text-center py-12 rounded-b-2xl shadow-lg">
          <h1 className="text-3xl md:text-5xl font-bold mb-4">
            Discover Your Path to Sustainable Weight Management
          </h1>
          <p className="text-md md:text-xl opacity-90">
            Take our science-backed 2-minute quiz to uncover your personalized
            weight loss plan
          </p>
        </header>

        <main>
          <div className="flex flex-col md:flex-row justify-center gap-8 my-8">
            <div className="text-center">
              <div className="text-4xl font-bold text-[#2d3d23]">25,000+</div>
              <div>Success Stories</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-[#2d3d23]">83%</div>
              <div>Success Rate</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-[#2d3d23]">2 min</div>
              <div>Quick Assessment</div>
            </div>
          </div>

          <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 my-8">
            {[
              {
                imgSrc: "assets/s1.png",
                title: "Step 1",
                text: "Take the <strong>2-minute</strong> Weight Quiz",
              },
              {
                imgSrc: "assets/s2.png",
                title: "Step 2",
                text: "Know the <strong>root cause</strong> of your weight gain and <strong>ideal weight</strong>",
              },
              {
                imgSrc: "assets/s3.png",
                title: "Step 3",
                text: "Get <strong>personalised</strong> product recommendations",
              },
              {
                imgSrc: "assets/s4.png",
                title: "Step 4",
                text: "Get a <strong>10-minute</strong> free consultation",
              },
            ].map((benefit, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow transition-transform transform hover:translate-y-1"
              >
                <img
                  src={benefit.imgSrc}
                  alt={`Benefit ${index + 1}`}
                  className="h-56 w-full object-cover rounded-lg"
                />
                <div className="text-center mt-4 md:mt-8">
                  <h4 className="font-semibold mb-4 md:mb-7">
                    {benefit.title}
                  </h4>
                  <p dangerouslySetInnerHTML={{ __html: benefit.text }}></p>
                </div>
              </div>
            ))}
          </section>

          <div
            className="sticky bottom-5 text-center mb-8 cursor-pointer inline-block bg-orange-600 text-white py-3 px-6 rounded-full text-sm md:text-lg font-semibold shadow-md transition-transform transform hover:translate-y-1"
            onClick={() => setIsQuizStarted(false)}
          >
            {/* <a href="https://assessment.thehealthspanco.com" className="inline-block bg-orange-600 text-white py-3 px-6 rounded-full text-lg font-semibold shadow-md transition-transform transform hover:translate-y-1"> */}
            Start Your Free Assessment
            {/* </a> */}
          </div>

          <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
            {[
              {
                imgSrc: "assets/u1.jpeg",
                name: "Rajesh S.",
                testimonial:
                  "The personalized approach made all the difference. Lost 10 kg in 3 months following my custom plan!",
              },
              {
                imgSrc: "assets/u2.jpeg",
                name: "Anita P.",
                testimonial:
                  "Finally found a sustainable way to manage my weight. No more yo-yo dieting!",
              },
              {
                imgSrc: "assets/u3.jpeg",
                name: "Sunil K.",
                testimonial:
                  "The insights about my habits were eye-opening. Making small changes led to big results.",
              },
            ].map((testimonial, index) => (
              <div
                key={index}
                className="bg-white flex flex-col gap-3 text-center items-center md:items-start md:text-start p-6 rounded-lg shadow transition-transform transform hover:translate-y-1"
              >
                <img
                  src={testimonial.imgSrc}
                  alt={testimonial.name}
                  className="w-20 h-20 rounded-full  mb-4"
                />
                <p className="font-semibold ">{testimonial.name}</p>
                <p className="text-md">{testimonial.testimonial}</p>
              </div>
            ))}
          </section>
        </main>

        <footer className="bg-gradient-to-r bg-[#2d3d23]  text-white text-center p-6 mt-12 flex flex-col items-center gap-2">
          <h2 className="text-2xl font-bold">
            Ready to Transform Your Health?
          </h2>
          <p className="my-2">
            Join thousands who've discovered their path to lasting weight
            management
          </p>
          <div
            onClick={() => setIsQuizStarted(false)}
            className="inline-block bg-orange-600 text-white py-3 px-6 rounded-full text-sm cursor-pointer md:text-md sticky md:relative bottom-5 md:bottom-0 md:text-lg font-semibold shadow-md transition-transform transform hover:translate-y-1"
          >
            Take the Free Quiz Now
          </div>
          <div className="flex relative justify-center gap-2 mt-4 opacity-90 text-sm">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              className="w-4 h-5 absolute top-0 -left-1 md:-left-5"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
            </svg>
            <span>
              Your information is protected by industry-leading encryption
            </span>
          </div>
        </footer>
      </div>

      {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
      {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
    </div>
  );
};
